import { delay, fork, select } from "redux-saga/effects";
import { call, put } from "redux-saga/effects";
import * as actions from "../actions";
import {
  selectCurrentWorkspaceRole,
  selectUser,
  setErrorMsg,
} from "../../features/auth/authSlice";
import librarySlice, {
  LibraryState,
  selectDocumentTemplates,
  selectLibrary,
  setError as setDocumentTemplateError,
  setStatus as setDocumentTemplateStatus,
  setDocumentTemplatesStatus,
  setDocumentTemplates,
  setFavourites,
  setImagePreview,
  setPreview,
  setPreviewOpen,
  setRecordImagePreview,
  updateDocumentTemplateLocal,
  setGroupByTempaltes,
  setSearchRelevance,
  setDocumentUniqueName,
  setCreditCount,
  setRemoveDocument,
} from "../../features/library/librarySlice";

import {
  setBulkRecordsStatus,
  setDocumentRecords,
  setPreviewStatus,
  setRemoveRecord,
} from "../../features/library/documentRecordsSlice";
import { User } from "../../types/User";
import {
  apiAgainPublishDocumentTemplate,
  apiApprovalPublishDocumentTemplate,
  apiCloneDocumentTemplate,
  apiCreateDocumentTemplate,
  apiDeleteDocumentRecord,
  apiDeleteDocumentTemplate,
  apiDraftCollaborationDocumentTemplate,
  apiExecuteDocumentTemplateImport,
  apiExecuteImpactAnalysis,
  apiFavouriteDocumentTemplate,
  apiGenerateDocumentPreview,
  apiGetDocumentNameSuggestion,
  apiGetDocumentPreview,
  apiGetDocumentRecordImagePreview,
  apiGetDocumentTemplateImagePreview,
  apiGetDocumentTemplates,
  apiGetFilteredDocumentTemplates,
  apiGetVariables,
  apiImportDocumentTemplate,
  apiImportDocumentTemplateFromDocConclude,
  apiMakeNormalDocumentTemplate,
  apiMoveDocumentTemplate,
  apiPublishDocumentTemplate,
  apiSubmitForApprovalDocumentTemplate,
  apiUnDeleteDocumentRecord,
  apiUndeleteDocumentTemplate,
  apiUnfavouriteDocumentTemplate,
  apiUpdateDocumentTemplate,
  apiUpdateDocumentTemplateImportText,
  apiUpdateImportDocumentTemplate,
  apiUploadDocumentElementImage,
} from "../../services/documentTemplatesAPI";

import { apiGetDocumentTemplate } from "../../services/documentTemplatesAPI";

import {
  clearApprovals,
  clearImpactState,
  clearPreview,
  declineChangedElement,
  disableApprovalMode,
  getImpact,
  selectApprovalState,
  selectApprovedChangedElements,
  selectApprovedChangedSections,
  selectApprovedDeletedElements,
  selectApprovedDeletedSections,
  selectApprovedDeletedSubsections,
  selectDeclinedChangedElements,
  selectDeclinedDeletedSections,
  selectDeclinedDeletedSubsections,
  selectEditingDocumentTemplate,
  setDocumentTemplate,
  setImpact,
  setNeedsApproval,
} from "../../features/editor/editorSlice";
import {
  ApprovalPayload,
  DocumentSection,
  DocumentTemplate,
  DocumentsFilters,
  DocumentRecord,
  Workspace,
  Favourite,
  ApprovalPayloadObject,
  ImpactStateItem,
} from "../../types/DocumentTemplate";
import { setDocumentMasterLocal } from "../../features/editor/header/documentMasterSlice";
import { useState } from "react";

/**
 * Get all document templates.
 *
 * @param {ReturnType<typeof actions.getDocumentTemplates>} action
 * @return {*}
 */

export function* createDocumentTemplate(
  action: ReturnType<typeof actions.createDocumentTemplate>
) {
  yield put(disableApprovalMode());
  yield put(
    setDocumentTemplateStatus({ status: "loading", error: "isLoading" })
  );
  try {
    const userState = (yield select(selectUser)) as User;
    const createResponse = yield call(
      apiCreateDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );

    const getDocTempt = actions.getDocumentTemplates();
    yield put(getDocTempt);

    const response = yield call(
      apiGetDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      createResponse.data.document_template_id
    );

    yield put(setDocumentTemplate(response));
    // Get the document master
    yield put(actions.getDocumentMaster());
    yield put(setDocumentTemplateStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setDocumentTemplateError("Conflict"));
    return;
  }
}

export function* getDocumentTemplates(
  action: ReturnType<typeof actions.getDocumentTemplates>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));
  yield put(setDocumentTemplatesStatus({ documentTemplatesStatus: "loading" }));

  try {
    const userState = (yield select(selectUser)) as User;
    const librarySlice = (yield select(selectLibrary)) as LibraryState;

    // clear previous Documnent because when we swicth the workspace the document of previous workspace appear at first after that new documents

    const isFilterApplied =
      librarySlice.filterString !== "" ||
      librarySlice.viewRecents ||
      librarySlice.viewFavourites ||
      librarySlice.viewDraft ||
      librarySlice.viewTemplates ||
      librarySlice.viewPublished ||
      librarySlice.viewOnceOffDocumnet ||
      librarySlice.viewDeleted ||
      librarySlice.viewDocumentRecords ||
      librarySlice.viewAuthoredByMe ||
      librarySlice.viewAwaitingApprovals ||
      librarySlice.groupBy !== "none";

    if (isFilterApplied) {
      // filter API playload
      const LibraryDocumentFilter: DocumentsFilters = {
        search: librarySlice.filterString,
        recent: librarySlice.viewRecents,
        favorites: librarySlice.viewFavourites,
        drafts: librarySlice.viewDraft,
        templates: librarySlice.viewTemplates,
        published: librarySlice.viewPublished,
        once_off_documents: librarySlice.viewOnceOffDocumnet,
        archived: librarySlice.viewDeleted,
        authored_by_me: librarySlice.viewAuthoredByMe,
        pdf_records: librarySlice.viewDocumentRecords,
        awaiting_approvals: librarySlice.viewAwaitingApprovals,
        group_by_category: librarySlice.groupBy === "Category" ? true : false,
        group_by_purpose:
          librarySlice.groupBy === "Sub-Category" ? true : false,
        group_by_temp_pdf: librarySlice.groupBy === "PDF" ? true : false,
      };

      const response = yield call(
        apiGetFilteredDocumentTemplates,
        userState.default_organization_id,
        userState.default_workspace_id,
        LibraryDocumentFilter
      );

      if (librarySlice.groupBy === "none") {
        yield put(
          setDocumentTemplates(
            response.data.document_templates.map((dt: DocumentTemplate) => ({
              ...dt,
              _type: "template",
            }))
          )
        );

        yield put(
          setDocumentTemplatesStatus({ documentTemplatesStatus: "idle" })
        );
        // set favourites Documents
        yield put(setFavourites(response.data.favourites));
        yield put(setBulkRecordsStatus(response.data.bulk_status));
        yield put(setCreditCount(response.data.credit_count));
        // if user search set relevance result according to documents
        yield put(setSearchRelevance(response.data.search_template));
        yield put(
          setDocumentRecords(
            response.data.document_records.map((dr: DocumentRecord) => ({
              ...dr,
              _type: "record",
            }))
          )
        );
      }

      // set the templates/ records according to the group by functionality
      else if (librarySlice.groupBy === "Category") {
        const reponseGroupBy = response.data.grouped_by_category;
        const group_by_category: { [key: string]: Array<any> } = {};
        Object.keys(reponseGroupBy).forEach((docName) => {
          const records = reponseGroupBy[docName].records;
          // can be Multiple
          const Templates = reponseGroupBy[docName].template;
          const combinedRecords = records.map((record: any) => ({
            ...record,
            _type: "record",
          }));
          const combinedTemplates = Templates.map((template: any) => ({
            ...template,
            _type: "template",
          }));

          if (!group_by_category[docName]) {
            group_by_category[docName] = [];
          }
          group_by_category[docName].push(...combinedTemplates);
          group_by_category[docName].push(...combinedRecords);
        });
        yield put(setGroupByTempaltes(group_by_category));
        yield put(
          setDocumentTemplatesStatus({ documentTemplatesStatus: "idle" })
        );
      } else if (librarySlice.groupBy === "Sub-Category") {
        // yield put(setGroupByTempaltes(response.data.grouped_by_subcategory));
        const reponseGroupBy = response.data.grouped_by_subcategory;
        const group_by_subcategory: { [key: string]: Array<any> } = {};

        Object.keys(reponseGroupBy).forEach((docName) => {
          const records = reponseGroupBy[docName].records;
          //Multipe Document Templates
          const Templates = reponseGroupBy[docName].template;
          const combinedRecords = records.map((record: any) => ({
            ...record,
            _type: "record",
          }));
          const combinedTemplates = Templates.map((template: any) => ({
            ...template,
            _type: "template",
          }));

          if (!group_by_subcategory[docName]) {
            group_by_subcategory[docName] = [];
          }
          group_by_subcategory[docName].push(...combinedTemplates);
          group_by_subcategory[docName].push(...combinedRecords);
        });
        yield put(setGroupByTempaltes(group_by_subcategory));
        yield put(
          setDocumentTemplatesStatus({ documentTemplatesStatus: "idle" })
        );
      } else if (librarySlice.groupBy === "PDF") {
        const reponseGroupBy = response.data.group_pdf_templates;
        const group_by_temp_pdf: { [key: string]: Array<any> } = {};

        Object.keys(reponseGroupBy).forEach((docName) => {
          const records = reponseGroupBy[docName].records;
          // single Document Template
          const template = reponseGroupBy[docName].template;

          const combinedData = records.map((record: any) => ({
            ...record,
            _type: "record",
          }));
          const templateWithType = Object.assign({}, template, {
            _type: "template",
          });
          if (!group_by_temp_pdf[docName]) {
            group_by_temp_pdf[docName] = [];
          }
          group_by_temp_pdf[docName].push(templateWithType);
          group_by_temp_pdf[docName].push(...combinedData);
        });
        yield put(setGroupByTempaltes(group_by_temp_pdf));
        yield put(
          setDocumentTemplatesStatus({ documentTemplatesStatus: "idle" })
        );
      } else {
        yield put(setGroupByTempaltes(undefined));
      }
    } else {
      yield put(setDocumentRecords([]));
      yield put(setDocumentTemplates([]));
      yield put(actions.getDocumentRecords());
      // if no filter selected get all documents Templates & records
      const response = yield call(
        apiGetDocumentTemplates,
        userState.default_organization_id,
        userState.default_workspace_id
      );

      // Set the local slice after updating with _type: "record"
      yield put(
        setDocumentTemplates(
          response.data.document_templates.map((dt: DocumentTemplate) => ({
            ...dt,
            _type: "template",
          }))
        )
      );
      yield put(
        setDocumentTemplatesStatus({ documentTemplatesStatus: "idle" })
      );
      yield put(
        setDocumentTemplateStatus({ status: "idle", error: undefined })
      );
      // yield put(
      //   setDocumentTemplates(
      //     response.data.document_templates.map((dt: DocumentTemplate) =>
      //       Object.assign({}, dt, { _type: "template" })
      //     )
      //   )
      // );
      yield put(setCreditCount(response.data.credit_count));
      yield put(setFavourites(response.data.favourites));
      yield put(setBulkRecordsStatus(response.data.bulk_status));
    }
    // add to not display the previous document master seetings on newly opend Document.
    yield put(setDocumentMasterLocal(undefined));

    yield put(setDocumentTemplatesStatus({ documentTemplatesStatus: "idle" }));
    yield put(setDocumentTemplateStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setDocumentTemplateError(String(e)));
    yield put(
      setDocumentTemplatesStatus({ documentTemplatesStatus: "failed" })
    );
    return;
  }
}

export function* updateDocumentTemplate(
  action: ReturnType<typeof actions.updateDocumentTemplate>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const createResponse = yield call(
      apiUpdateDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );

    // Update all the document templates.
    yield put(actions.getDocumentTemplates());
  } catch (e) {
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* updateDocumentTemplateImportText(
  action: ReturnType<typeof actions.updateDocumentTemplate>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;

    const createResponse = yield call(
      apiUpdateDocumentTemplateImportText,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.id,
      action.payload.import_text
        .map((_) => _.part_type + ";" + _.part_text)
        .join("\n")
    );

    // Update all the document templates.
    //yield put(actions.setDocumentTemplate());
  } catch (e) {
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* executeDocumentTemplateImport(
  action: ReturnType<typeof actions.updateDocumentTemplate>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;

    const updateResponse = yield call(
      apiUpdateDocumentTemplateImportText,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.id,
      action.payload.import_text
        .map((_) => _.part_type + ";" + _.part_text)
        .join("\n")
    );

    const createResponse = yield call(
      apiExecuteDocumentTemplateImport,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );

    yield put(actions.getDocumentTemplates());

    const response = yield call(
      apiGetDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.id
    );
    yield put(setDocumentTemplate(response));

    // Get the document master
    yield put(actions.getDocumentMaster());

    // Update all the document templates.
    //yield put(actions.setDocumentTemplate());
  } catch (e) {
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* getDocumentTemplateVariables(
  action: ReturnType<typeof actions.getDocumentTemplateVariables>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const uniqueNameResponse = yield call(
      apiGetDocumentNameSuggestion,
      action.payload,
      userState.default_workspace_id
    );

    yield put(setDocumentUniqueName(uniqueNameResponse.data.suggestion));

    const variablesResponse = yield call(
      apiGetVariables,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );

    // Update the local document template
    const newTemplate = Object.assign({}, action.payload, {
      variables: variablesResponse.data.document_variables,
    });

    yield put(updateDocumentTemplateLocal(newTemplate));
  } catch (e) {
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

//error in this preview function
export function* previewDocumentTemplate(
  action: ReturnType<typeof actions.previewDocumentTemplate>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    yield put(
      setDocumentTemplateStatus({ status: "loading", error: undefined })
    );
    yield put(setPreviewStatus("loading"));
    const userState = (yield select(selectUser)) as User;
    const previewResponse = yield call(
      apiGetDocumentPreview,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );

    console.log("Unable to generate a preview response", previewResponse);

    // Update all the document templates.
    yield put(setPreview(previewResponse.data));
    yield put(setPreviewStatus("idle"));

    yield put(setDocumentTemplateStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setPreviewStatus("failed"));
    yield put(
      setDocumentTemplateStatus({
        status: "failed",
        error:
          "Unable to generate a preview previewDocumentTemplate: " + String(e),
      })
    );
    console.log("Unable to generate a preview previewDocumentTemplate: ", e);
    return;
  }
}

export function* generateDocumentTemplate(
  action: ReturnType<typeof actions.generateDocumentTemplate>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));
  yield put(
    setErrorMsg({
      status: "loading",
      errorMsg: undefined,
      errorType: "generateDocumentTemplate",
    })
  );

  try {
    const userState = (yield select(selectUser)) as User;
    yield put(setPreviewOpen(true));
    const previewResponse = yield call(
      apiGenerateDocumentPreview,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );
    // Update all the document templates.
    yield put(setPreview(previewResponse.data));
    // console.log(" Document Preview")
    yield put(
      setErrorMsg({
        status: "success",
        errorMsg: undefined,
        errorType: "generateDocumentTemplate",
      })
    );

    yield put(setDocumentTemplateStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(clearPreview());
    yield put(
      setErrorMsg({
        status: "failed",
        errorMsg: "Unable to generate preview ",
        errorType: "generateDocumentTemplate",
      })
    );
    yield put(
      setDocumentTemplateStatus({
        status: "idle",
        error:
          "Unable to generate a preview in  generateDocumentTemplate: " +
          String(e),
      })
    );
    return;
  }
}

export function* publishDocumentTemplate(
  action: ReturnType<typeof actions.publishDocumentTemplate>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const DocumentTemplate = yield select(selectEditingDocumentTemplate);
    const workspaceRole = yield select(selectCurrentWorkspaceRole);

    // if user is creator we only subimit it for approval.... changed its state to Published..... conent is not fully published untill the publisher approves it
    if (
      workspaceRole !== "CREATOR" &&
      DocumentTemplate?.publication_no === null
    ) {
      const publishResponse = yield call(
        apiPublishDocumentTemplate,
        userState.default_organization_id,
        userState.default_workspace_id,
        action.payload
      );
      const { needs_approval } = publishResponse.data;

      const response = yield call(
        apiGetDocumentTemplate,
        userState.default_organization_id,
        userState.default_workspace_id,
        DocumentTemplate.id
      );

      yield put(setDocumentTemplate({ ...response, needs_approval }));
      yield put(setDocumentMasterLocal(response.document_template_master));
    } else {
      // if user is publisher we will  publish it ..
      const submitForApprovalResponse = yield call(
        apiSubmitForApprovalDocumentTemplate,
        userState.default_organization_id,
        userState.default_workspace_id,
        action.payload
      );

      const {
        needs_approval,
        new_content_added,
        publication_no,
        new_published_date,
      } = submitForApprovalResponse.data;
      
      const updatedDocTemplate = {
        ...DocumentTemplate,
        needs_approval,
        new_content_added,
        publication_no,
        new_published_date,
      };

      yield put(setDocumentTemplate(updatedDocTemplate));
      yield put(
        setDocumentMasterLocal(updatedDocTemplate.document_template_master)
      );
      // yield put(actions.setEditingDocumentTemplate(updatedDocTemplate));
    }

    if (DocumentTemplate) {
      //Nothing much to do ....
    } else {
      yield put(actions.getDocumentTemplates());
    }

    yield put(setDocumentTemplateStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* againPublishDocumentTemplate(
  action: ReturnType<typeof actions.againPublishDocumentTemplate>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const DocumentTemplate = yield select(selectEditingDocumentTemplate);

    const publishResponse = yield call(
      apiAgainPublishDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );

    // yield put(setNeedsApproval(publishResponse.data.needs_approval));
    const { needs_approval, new_content_added, publication_no } =
      publishResponse.data;

    if (DocumentTemplate) {
      const response = yield call(
        apiGetDocumentTemplate,
        userState.default_organization_id,
        userState.default_workspace_id,
        DocumentTemplate.id
      );

      yield put(
        setDocumentTemplate({
          ...response,
          needs_approval,
          new_content_added,
          publication_no,
        })
      );
      yield put(setDocumentMasterLocal(response.document_template_master));
    }
    yield put(setDocumentTemplateStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* approvalImpactChanges(
  action: ReturnType<typeof actions.approvalImpactChanges>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));
  try {
    const DocumentTemplate = yield select(selectEditingDocumentTemplate);
    const userState = (yield select(selectUser)) as User;
    const impactState = (yield select(selectApprovalState)) as [];

    const result: ApprovalPayloadObject[] = []; // Initialize the result array
    for (const tempSubsectionID in impactState) {
      if (Object.prototype.hasOwnProperty.call(impactState, tempSubsectionID)) {
        const subsectionObject = impactState[
          tempSubsectionID
        ] as ImpactStateItem[];
        // Create a new ApproveDisapproveTemplate object for this subsection
        if (Array.isArray(subsectionObject)) {
          const tempObject: ApprovalPayloadObject = {
            section_id: 124,
            subsection_id: parseInt(tempSubsectionID, 10), // Assuming subsection_id is numeric
            is_deleted: false,
            element_id: 0, // You may need to set the appropriate value for element_id
            uninclude_document_templates: [], // Initialize with an empty array
            include_document_templates: [], // Initialize with an empty array
          };
          subsectionObject.forEach((item: ImpactStateItem) => {
            const documentID = item.documentID;
            const status = item.status;
            tempObject.is_deleted = item.isDeleted;
            tempObject.element_id = item.elementId;
            tempObject.section_id = item.sectionID;
            if (status === "approve") {
              tempObject.include_document_templates.push(documentID);
            } else if (status === "reject") {
              // Corrected "reject" to "rejected"
              tempObject.uninclude_document_templates.push(documentID);
            }
          });
          // Add the template to the result array
          if (tempObject.element_id != 0) {
            result.push(tempObject);
          }
        }
      }
    }
    if (result.length === 0) {
      yield put(actions.againPublishDocumentTemplate(DocumentTemplate));
      yield put(disableApprovalMode());
      throw "no data available for impact changes";
    } else {
      yield put(disableApprovalMode());
      const publishResponse = yield call(
        apiExecuteImpactAnalysis,
        userState.default_organization_id,
        userState.default_workspace_id,
        action.payload,
        result
      );

      yield put(actions.againPublishDocumentTemplate(DocumentTemplate));
      yield put(disableApprovalMode());
    }
    // Handle the response as needed
    // Update all the document templates.

    // const selectDocumentTemplate = yield select(selectEditingDocumentTemplate);
    // // yield put(actions.getDcumentTemplates());
    // if (selectDocumentTemplate)
    //  yield put(actions.setOpenApprovalDocumentTemplate(action.payload))
    yield put(clearImpactState());
    yield put(setDocumentTemplateStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setDocumentTemplateError(String(e)));

    return;
  }
}
export function* approvalPublishDocumentTemplate(
  action: ReturnType<typeof actions.approvalPublishDocumentTemplate>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;

    const approvedChangedDocumentSections = (yield select(
      selectApprovedChangedSections
    )) as Array<number>;
    const approvedChangedElements = (yield select(
      selectApprovedChangedElements
    )) as Array<number>;
    const approvedDeletedSections = (yield select(
      selectApprovedDeletedSections
    )) as Array<number>;
    const approvedDeletedSubsections = (yield select(
      selectApprovedDeletedSubsections
    )) as Array<number>;
    const approvedDeletedElements = (yield select(
      selectApprovedDeletedElements
    )) as Array<number>;

    const declinedDeletedSubsections = (yield select(
      selectDeclinedDeletedSubsections
    )) as Array<number>;
    const declinedDeletedSections = (yield select(
      selectDeclinedDeletedSections
    )) as Array<number>;

    const declinedChangedElements = (yield select(
      selectDeclinedChangedElements
    )) as Array<number>;
    // = (yield select(selectDeclinedDeletedElements)) as Array<number>;

    const impact = (yield select(getImpact)) as Array<
      Array<DocumentTemplate | Workspace | DocumentSection>
    >;

    const unincludeDocs = impact
      .filter((_) => !(_[2] as DocumentSection).impact_section_include)
      .map((__) => {
        return [__[1].id, __[2].id];
      });

    //console.log("Show approved changes", approvedChangedElements);

    const approvalsPayload: ApprovalPayload = {
      approved_document_sections: approvedChangedDocumentSections,
      approved_document_sub_sections: [],
      approved_document_elements: approvedChangedElements,

      approved_sections_sub_sections: [],
      approved_templates_sections: [],

      declined_document_sections: [],
      declined_document_sub_sections: [],
      declined_document_elements: declinedChangedElements,

      declined_sections_sub_sections: [],
      declined_templates_sections: [],

      deletions_document_sections: approvedDeletedSections,
      deletions_document_sub_sections: approvedDeletedSubsections,
      deletions_document_elements: approvedDeletedElements,

      declined_deletions_document_sections: declinedDeletedSections,
      declined_deletions_document_sub_sections: declinedDeletedSubsections,

      moved_document_sections: [],
      moved_document_sub_sections: [],

      uninclude_document_templates: unincludeDocs,
    };

    const publishResponse = yield call(
      apiApprovalPublishDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload,
      approvalsPayload
    );

    // Update all the document templates.

    yield put(clearApprovals());
    const selectDocumentTemplate = yield select(selectEditingDocumentTemplate);
    // yield put(actions.getDocumentTemplates());
    if (selectDocumentTemplate)
      yield put(actions.setEditingDocumentTemplate(selectDocumentTemplate));
    yield put(setDocumentTemplateStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* deleteDocumentTemplate(
  action: ReturnType<typeof actions.deleteDocumentTemplate>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const librarySlice = (yield select(selectLibrary)) as LibraryState;
    const response = yield call(
      apiDeleteDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );
    if (librarySlice.groupBy == "none") {
      yield put(
        setRemoveDocument({
          documentID: action.payload.id,
        })
      );
    } else {
      yield put(actions.getDocumentTemplates());
    }
    // Update all the document templates.
  } catch (e) {
    console.error(e);
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* deleteDocumentRecord(
  action: ReturnType<typeof actions.deleteDocumentRecord>
) {
  // yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));
  try {
    const userState = (yield select(selectUser)) as User;
    const librarySlice = (yield select(selectLibrary)) as LibraryState;
    const response = yield call(
      apiDeleteDocumentRecord,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );

    if (librarySlice.groupBy === "none") {
      yield put(
        setRemoveRecord({
          documentID: action.payload.id,
          isRecord: true,
        })
      );
    } else {
      yield put(actions.getDocumentTemplates());
    }
    // Update all the document templates.
  } catch (e) {
    console.error(e);
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* cloneDocumentTemplate(
  action: ReturnType<typeof actions.cloneDocumentTemplate>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiCloneDocumentTemplate as any,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.document_template,
      action.payload.target_workspace_id
    );

    // Update all the document templates.
    //yield put(actions.getDocumentTemplates());
  } catch (e) {
    console.error(e);
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* moveDocumentTemplate(
  action: ReturnType<typeof actions.cloneDocumentTemplate>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    yield put(
      setErrorMsg({
        status: "loading",
        errorMsg: undefined,
        errorType: "moveDocumentTemplate",
      })
    );

    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiMoveDocumentTemplate as any,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.document_template,
      action.payload.target_workspace_id
    );

    yield put(
      setErrorMsg({
        status: "success",
        errorMsg: undefined,
        errorType: "moveDocumentTemplate",
      })
    );

    // Update all the document templates.
    yield put(actions.getDocumentTemplates());
  } catch (e) {
    console.error(e);
    yield put(
      setErrorMsg({
        status: "failed",
        errorMsg: undefined,
        errorType: "moveDocumentTemplate",
      })
    );

    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* makeNormalDocumentTemplate(
  action: ReturnType<typeof actions.makeNormalDocumentTemplate>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiMakeNormalDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );

    // Update all the document templates.
    yield put(actions.getDocumentTemplates());
  } catch (e) {
    console.error(e);
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}
export function* draftCollaborationDocumentTemplate(
  action: ReturnType<typeof actions.draftCollaborationDocumentTemplate>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiDraftCollaborationDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.document_template,
      action.payload.updatedDocumentStatus
    );

    // Update all the document templates.
    yield put(actions.getDocumentTemplates());
  } catch (e) {
    console.error(e);
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* undeleteDocumentTemplate(
  action: ReturnType<typeof actions.undeleteDocumentTemplate>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiUndeleteDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );
    // console.log(response);

    // Update all the document templates.
    yield put(actions.getDocumentTemplates());
  } catch (e) {
    console.error(e);
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* undeleteDocumentRecord(
  action: ReturnType<typeof actions.undeleteDocumentRecord>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiUnDeleteDocumentRecord,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );
    // console.log(response);

    // Update all the document templates.
    yield put(actions.getDocumentTemplates());
  } catch (e) {
    console.error(e);
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* importDocumentTemplateFromDoc(
  action: ReturnType<typeof actions.importDocumentTemplateFromDoc>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    yield put(
      setErrorMsg({
        status: "loading",
        errorMsg: undefined,
        errorType: "importDocumentTemplateFromDoc",
      })
    );

    const userState = (yield select(selectUser)) as User;
    const importResponse = yield call(
      apiImportDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.file
    );

    if (importResponse.status === 200) {
      const updateTemplateName = yield call(
        apiUpdateImportDocumentTemplate,
        userState.default_organization_id,
        userState.default_workspace_id,
        importResponse.data.document_template_id,
        action.payload.name,
        action.payload.category,
        action.payload.subCategory,
        action.payload.isOnceOff
      );

      // If successful, execute the second API call
      const concludeResponse = yield call(
        apiImportDocumentTemplateFromDocConclude,
        userState.default_organization_id,
        userState.default_workspace_id,
        importResponse.data.document_template_id,
        action.payload.name,
        action.payload.category,
        action.payload.subCategory,
        action.payload.isOnceOff
      );

      const response = yield call(
        apiGetDocumentTemplate,
        userState.default_organization_id,
        userState.default_workspace_id,
        importResponse.data.document_template_id
      );
      yield put(
        setErrorMsg({
          status: "success",
          errorMsg: undefined,
          errorType: "importDocumentTemplateFromDoc",
        })
      );
      yield put(setDocumentTemplate(response));
      yield put(setDocumentMasterLocal(response.document_template_master));
    }
    // Update all the document templates.
    // yield put(actions.getDocumentTemplates());
  } catch (e) {
    yield put(
      setErrorMsg({
        status: "failed",
        errorMsg: "Document with this name already exist",
        errorType: "importDocumentTemplateFromDoc",
      })
    );
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}
export function* importDocumentTemplate(
  action: ReturnType<typeof actions.importDocumentTemplate>
) {
  yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const importResponse = yield call(
      apiImportDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );

    console.log(importResponse);

    // Update all the document templates.
    yield put(actions.getDocumentTemplates());
  } catch (e) {
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* favouriteDocumentTemplate(
  action: ReturnType<typeof actions.favouriteDocumentTemplate>
) {
  // yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const librarySlice = (yield select(selectLibrary)) as LibraryState;

    const favoritesList: Favourite[] = [
      ...librarySlice.favourites,
      {
        document_template_id: action.payload.id,
        id: 73, // random id to set state
        user_id: 100, // random id to set state
      },
    ];
    yield put(setFavourites(favoritesList));

    const importResponse = yield call(
      apiFavouriteDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );

    // Update all the document templates.
    // yield put(actions.getDocumentTemplates());
  } catch (e) {
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* unfavouriteDocumentTemplate(
  action: ReturnType<typeof actions.unfavouriteDocumentTemplate>
) {
  // yield put(setDocumentTemplateStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;

    const librarySlice = (yield select(selectLibrary)) as LibraryState;
    // remove the selected document from then favorite state
    const favoritesList: Favourite[] = librarySlice.favourites.filter(
      (favorite) => favorite.document_template_id !== action.payload.id
    );

    yield put(setFavourites(favoritesList));
    const importResponse = yield call(
      apiUnfavouriteDocumentTemplate,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );
    // Update all the document templates.
    // yield put(actions.getDocumentTemplates());
  } catch (e) {
    yield put(setDocumentTemplateError(String(e)));
    return;
  }
}

export function* getDocumentImagePreview(
  action: ReturnType<typeof actions.getDocumentImagePreview>
) {
  // Set loading.
  yield put(
    setImagePreview({
      documentTemplate: action.payload,
      imagePreview: { status: "loading", data: null },
    })
  );

  try {
    const userState = (yield select(selectUser)) as User;
    //Image preview for the All the Template of LIbrary Screen.
    // const imagePreviewResponse = yield call(
    //   apiGetDocumentTemplateImagePreview,
    //   userState.default_organization_id,
    //   userState.default_workspace_id,
    //   action.payload
    // );
    // yield put(
    //   setImagePreview({
    //     documentTemplate: action.payload,
    //     imagePreview: { status: "idle", data: imagePreviewResponse.data },
    //   })
    // );
  } catch (e) {
    yield put(
      setImagePreview({
        documentTemplate: action.payload,
        imagePreview: { status: "failed", data: String(e) },
      })
    );
    return;
  }
}

export function* getRecordImagePreview(
  action: ReturnType<typeof actions.getRecordImagePreview>
) {
  // Set loading.
  yield put(
    setRecordImagePreview({
      documentRecord: action.payload,
      imagePreview: { status: "loading", data: null },
    })
  );

  try {
    const userState = (yield select(selectUser)) as User;
    // const imagePreviewResponse = yield call(
    //   apiGetDocumentRecordImagePreview,
    //   userState.default_organization_id,
    //   userState.default_workspace_id,
    //   action.payload
    // );
    // yield put(
    //   setRecordImagePreview({
    //     documentRecord: action.payload,
    //     imagePreview: { status: "idle", data: imagePreviewResponse.data },
    //   })
    // );
  } catch (e) {
    yield put(
      setRecordImagePreview({
        documentRecord: action.payload,
        imagePreview: { status: "failed", data: String(e) },
      })
    );
    return;
  }
}
