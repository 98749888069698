export enum ContentType {
  TitlePage = "TITLEPAGE",
  TableOfContents = "TABLEOFCONTENTS",
  ListOfFigures = "LISTOFFIGURES",
  LibraryContentSubSection = "LIBRARYCONTENTSUBSECTION",
  Heading = "HEADING",
  Text = "TEXT",
  // FillableField = "FILLABLEFIELD",

  // CoverPage = "COVERPAGE",
  Image = "IMAGE",
  Table = "TABLE",
  // Bibliography = "BIBLIOGRAPHY",
  Spacer = "SPACER",
  PageBreak = "PAGEBREAK",

  listOfTables = "LISTOFTABLES",
  LibraryContent = "LIBRARYCONTENT",
  columnStart = "COLUMNSTART",
  columnEnd = "COLUMNEND",
  dynamicTable = "DYNAMICTABLE",
}

export enum ContentFormat {
  Markdown = "MARKDOWN",
  File = "FILE",
  Text = "TEXT",
}

export enum SectionSetting {
  ContinueNumbering = "Continue Numbering",
  RestartNumbering = "Restart Numbering",
  NoNumbering = "No Numbering",
}

export interface DocumentsFilters {
  search: string;
  recent: boolean;
  favorites: boolean;
  drafts: boolean;
  templates: boolean;
  published: boolean;
  once_off_documents: boolean;
  archived: boolean;
  authored_by_me: boolean;
  pdf_records: boolean;
  awaiting_approvals: boolean;
  group_by_category: boolean;
  group_by_purpose: boolean;
  group_by_temp_pdf: boolean;
}

export interface DocumentElement {
  id: number;
  content: string;
  content_format: ContentFormat | string;
  content_type: ContentType;
  content_order: number;
  pending_addition: boolean;
  heading_level: number;
  formatting?: string | any;
  edit: boolean;
  document_sub_section_id: number;
  valid_from: string | null;
  valid_until: string | null;
  publication_no: string | null;
  created_at?: string | null;
  difference_content?: string | null;
  element_approvals?: boolean | null;
  should_show?: boolean | null;
  approved_by?: string | null;
  published_by?: string | null;
  approved_date?: string | null;
  supercedes_id?: number | null;
}

export interface DocumentSubsection {
  id: number;
  heading: string;
  component_order: number;
  elements?: DocumentElement[];
  // Edit states

  pending_deleted_at: string | null;
  pending_heading: string | null;
  pending_addition: boolean;
  temporary_deleted?: string | null;
}

export interface DocumentSectionCreateRequest {
  heading: string;
  component_order: number;
  heading_level: number;
  content_format?: string;
  formatting?: string;
}

export interface TitleSectionCreateRequest {
  id: number;
  title: string;
  date: string;
  component_order: number;
}

export interface DocumentSection extends DocumentSectionCreateRequest {
  previous_id?: number;
  id: number;
  edit: boolean;
  subsections?: DocumentSubsection[];
  versionedSubsection?: DocumentSubsection[];
  // Edit states
  //Soft Delete
  pending_deleted_at: string | null;
  // user changes to published heading.
  pending_heading: string | null;
  pending_addition: boolean;
  heading_level: number;
  publication_no?: string | null;

  // show Difference on Approval Screen.
  difference_content?: string | null;
  pending_heading_level: number | null;
  impact_section_include: boolean;
  // To implement the Style Properties
  is_new?: boolean;
  document_section_id?: number;
  cross_ref?: any[];
}

export interface DocumentSectionSummary {
  previous_id?:number
  id: number;
  heading: string;
  sample_document_elements: DocumentElement[];
  sample_document_sub_sections: DocumentSubsection[];
  difference_content?: string | null;
  // Edit states
  pending_deleted_at: string | null;
  pending_heading: string | null;
  heading_level: number;
  pending_heading_level: number | null;
  formatting?: string;
}

export interface Workspace {
  id: number;
  name: string;
  organization_id: number;
}

export interface DocumentTemplateCommon {
  name: string;
  title: string;
  purpose: string;
  category: string;
  body: string;
}

export interface DocumentTemplateCreateRequest extends DocumentTemplateCommon {
  body: string;
  once_off: boolean;
}

export interface DocumentTemplateSummary extends DocumentTemplateCommon {
  id: number;
  document_section_summaries: DocumentSectionSummary[];
  document_template_master: any;
}

export interface DocumentTemplateImagePreview {
  data: Blob | string | null;
  status: "idle" | "loading" | "failed";
}

export interface DocumentTemplate extends DocumentTemplateCreateRequest {
  id: number;
  email?: string;
  needs_approval: boolean; // is Document is in approvals?
  sections?: DocumentSection[];
  variables?: Variable[];
  references?: Reference[];
  document_template_master: any;
  created_at: string | null;
  deleted_at: string | null;
  updated_at: string | null;
  doc_status?: string;
  locked_by?: number | null;
  is_draft_opened?: boolean;
  // Document published by publisher
  published_date: string | null;
  // if creator sents for pushlish for 1st time
  new_published_date?: string | null;
  imagePreview?: DocumentTemplateImagePreview;
  _type?: "template" | "record";
  // Assiged by Publisher on Fully approve Dcocument.
  publication_no?: number | null;
  import_mode: boolean;
  import_text: Array<{ part_type: string; part_text: string }>;
  imported_sections?: [];
  imported_sub_sections?: [];
  included_subSections?: [];
  included_section?: [];
  // soft deleted  Libaray imported subsecions.
  deleted_sub_sections: number[];
  is_undo_show: number[];
  user_email?: string;
  // if new content added in Document
  new_content_added?: boolean;
  // if creator send for Approval to publisher
  send_for_approvals?: boolean;
  sections_sub_section?: [];
  image_content_format?: string;
  // List to where show the versions
  is_version_show?: [];
  // show to publisher that document in editor state
  is_editing?: boolean;
  // all subsections Element version
  element_version: DocumentElement[] | null; // all the versioned elements

  is_version_available?: []; // to show on version is avaiable for this subsection
  locked_by_email?: string | null;
}
export interface Favourite {
  id: number;
  user_id: number;
  document_template_id: number;
}

export interface DocumentRecord {
  id: number;
  name: string;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  publication_date: string | null;
  document_template_id: number;
  template_name?: string;
  user_email?: string;

  variable_values: string;
  workspace_id: number;

  imagePreview?: DocumentTemplateImagePreview;
  _type?: "template" | "record";
  sub_category?: string;
  category?: string;
  hidden_link: string;
}

export interface VariableCreateRequest {
  variable_name: string;
  default_value: string;
  fillable_field: boolean;
  field_type: string;
  content_format: string | '{"height":"4mm","width":"10mm","boarder":"F"}';
  // content_format: string;
}

export interface ReferenceCreateRequest {
  tag: string;
  title: string;
  authors: string;
  journal: string;
  volume: string;
  number: string;
  pages: string;
  year: string;
  publisher: string;
}

export interface Variable extends VariableCreateRequest {
  id: number | null;
  created_at: string | null;
  deleted_at: string | null;
  updated_at: string | null;
}

export interface Reference extends ReferenceCreateRequest {
  id: number | null;
  created_at: string | null;
  deleted_at: string | null;
  updated_at: string | null;
}

export interface ApprovalPayload {
  approved_document_sections: Array<number>;
  approved_document_sub_sections: Array<number>;
  approved_document_elements: Array<number>;

  approved_sections_sub_sections: Array<number>;
  approved_templates_sections: Array<number>;

  declined_document_sections: Array<number>;
  declined_document_sub_sections: Array<number>;
  declined_document_elements: Array<number>;

  declined_sections_sub_sections: Array<number>;
  declined_templates_sections: Array<number>;

  deletions_document_sections: Array<number>;
  deletions_document_sub_sections: Array<number>;
  deletions_document_elements: Array<number>;

  declined_deletions_document_sections: Array<number>;
  declined_deletions_document_sub_sections: Array<number>;

  moved_document_sections: Array<number>;
  moved_document_sub_sections: Array<number>;

  uninclude_document_templates: Array<Array<number>>;
}

export interface ImpactStateItem {
  sectionID: number;
  documentID: number;
  documentName: string;
  status: "pending" | "approve" | "reject";
  elementId: number;
  isDeleted: boolean;
}

export interface ApprovalPayloadObject {
  section_id: number;
  subsection_id: number;
  element_id: number;
  is_deleted: boolean;
  uninclude_document_templates: number[]; // Changed from empty array to number array
  include_document_templates: number[]; // Changed from empty array to number array
}

export interface TableElementTheme_ {
  name: string;
  rows: number;
  columns: number;
  THBackgroundColor: string;
  THTextColor: string;
  THTextAlign: string;
  CellBorderColor: string;
  CellBorderStyle: string;
  CellBorderThickness: string;
  CellTextAlign: string;
  evenRowBackgroundColor: string;
  CellTextColor: string;
  CellFontSize: string;
  THFontSize: string;
  CellBackgroundColor: string;
  stripe: string; /// row column none
  StripeBackgroundColor: string;
}
