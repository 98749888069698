import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import {
  ContentType,
  DocumentElement,
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateSummary,
} from "../../../types/DocumentTemplate";
import { ViewPreferences } from "../header/preferencesSlice";
//import { BibliographyElementEditor } from "./BibliographyElementEditor";

// import { DocumentElementEditor } from "./DocumentElementEditor";
import DocumentElementEditor from "./DocumentElementEditor";

import { TableElementEditor } from "./TableElement/TableElementEditor";
import { ImageElementEditor } from "./images/ImageElementEditor";
import { ListOfFiguresElementEditor } from "./ListOfFiguresElementEditor";
import { PagebreakElementEditor } from "./PagebreakElementEditor";
import { TOCElementEditor } from "./TOCElementEditor";
import { CoverPageElementEditor } from "./CoverPageElementEditor";
import { SpacerElementEditor } from "./SpacerElementEditor";
import { FillableFieldElementEditor } from "./FillableFieldElementEditor";
import React, { useState } from "react";
import { ColumnElementEditor } from "./ColumnElementEditor";
import { DynamicTableElementEditor } from "./TableElement/DynamicTable/DynamicTableElementEditor";
import { isUserFromShareLink } from "./TableElement/EditContent/helper";
import { countTableElementInDocumentTemplate } from "./HelperEditorFunctions";

const remarkableColorPlugin = (md: any) => {
  md.inline.ruler.before("emphasis", "color", (state: any, silent: any) => {
    const startPos = state.pos;

    // Check if the current position is at the beginning of a span tag
    if (
      state.src[startPos] === "<" &&
      state.src.slice(startPos, startPos + 6) === "<span "
    ) {
      const openingTagMatch = state.src
        .slice(startPos)
        .match(/<span style="color:\s*([^"]+)"/);

      if (openingTagMatch) {
        const color = openingTagMatch[1];

        // Call the tokenizer and add a color_open token
        state.push({
          type: "color_open",
          level: state.level,
          content: color,
        });

        // Skip the matched part in the source
        state.pos += openingTagMatch[0].length;

        // Save the start position for the content between the tags
        const contentStartPos = state.pos;

        // Look for the closing </span> tag
        const closingTagMatch = state.src.slice(state.pos).match(/<\/span\s*>/);

        if (closingTagMatch) {
          // Push the content between the tags as a text token
          state.push({
            type: "text",
            level: state.level + 1,
            content: state.src.slice(contentStartPos, state.pos),
          });

          // Skip the matched part in the source
          state.pos += closingTagMatch[0].length;
        } else {
          // If there is no closing tag, reset the position to the starting position
          state.pos = startPos;
        }

        // Call the tokenizer and add a color_close token
        state.push({
          type: "color_close",
          level: state.level,
        });

        console.log("Successfully tokenized color:", color);
        console.log("Remaining source:", state.src.slice(state.pos));

        return true; // Tokenized successfully
      }
    }

    // If not a match, indicate that the rule failed
    return false;
  });
};

// const markdownToDraftOptionss = {
//   // remarkablePlugins: [remarkableColorPlugin],
//   blockStyles: {
//     ins_open: 'UNDERLINE',
//     color_open:'COLOR_RED'
//   },

//   blockEntities: {
//     span: function (item:any) {
//       // Assuming 'item' contains information about the span element
//       console.log("I'm in span entity");
//       return {
//         type: "COLOR",
//         mutability: "IMMUTABLE",
//         data: {
//           color: item.color || 'red', // You can extract color information from the item
//         }
//       };
//     },
//   },

//   blockTypes: {
//     span: function (item:any) {
//       console.log("I'm in span block type");
//       return {
//         type: 'unstyled',
//         text: 'This text should be red',
//       };
//     },
//   },

//   remarkableOptions: {
//   html:true,
//     enable: {
//       inline: ['ins'  ],
//     },
//   },
// };
// const newEditorState= markdownToDraft(markdown, markdownToDraftOptions)

function preprocessMarkdown(markdown:any) {
  return markdown.replace(/\s+/g, " ").trim();
}

const markdownToDraftOptions = {
  remarkablePlugins: [], 
  inlineStyles: {
    STRIKETHROUGH: { open: "~~", close: "~~", style: "STRIKETHROUGH" },
    CODE: { open: "`", close: "`", style: "CODE" },
  },
  blockEntities: {}, 
};

export function markdowntoEditorState(markdown: string) {
  const preprocessedMarkdown = preprocessMarkdown(markdown);
  const rawDraftJSObject = markdownToDraft(preprocessedMarkdown, markdownToDraftOptions);
  return convertFromRaw(rawDraftJSObject);
}
interface CustomStyleItem {
  open: (value: string) => string;
  close: () => string;
}

interface CustomStyleItems {
  [key: string]: CustomStyleItem;
}

const draftToMarkdownOptions = {
  preserveNewlines: true,
  styleItems: {
    UNDERLINE: {
      open: function open() {
        return "++";
      },

      close: function close() {
        return "++";
      },
    },
  },
  // Needs work to be finished
  // entityItems: {
  //   "text-align": {
  //     open: function open(entity: any) {
  //       console.log("HERE!");
  //       return "%C%";
  //     },
  //     close: function close(entity: any) {
  //       return "%/C%";
  //     },
  //   },
  //   textAlign: {
  //     open: function open(entity: any) {
  //       console.log("HERE!");
  //       return "%C%";
  //     },
  //     close: function close(entity: any) {
  //       return "%/C%";
  //     },
  //   },
  //   text_align: {
  //     open: function open(entity: any) {
  //       console.log("HERE!");
  //       return "%C%";
  //     },
  //     close: function close(entity: any) {
  //       return "%/C%";
  //     },
  //   },
  //   mention: {
  //     open: function (entity: any) {
  //       return '<span class="mention-item" data-user-id="">';
  //     },

  //     close: function (entity: any) {
  //       return "</span>";
  //     },
  //   },
  //   //R&D
  //   COLOR: {
  //     open: function open(entity: any) {
  //       // Assuming the color information is stored in the entity
  //       const color = entity.data.color || "000000";
  //       return `<span style="color:${color}">`;
  //     },
  //     close: function close(entity: any) {
  //       return "</span>";
  //     },
  //   },
  // },
};

export function editorStateToMarkdown(editorState: EditorState) {
  return normalizeText(
    draftToMarkdown(
      convertToRaw(editorState.getCurrentContent()),
      draftToMarkdownOptions
    )
  );
}

/**
 * Trim whitespace without trimming newlines.
 *
 * @param {string} s
 * @return {*}  {string}
 */
export function normalizeText(s: string): string {
  try {
    if (s.endsWith(" ")) return s.substring(0, s.length - 1).normalize();
    return s.normalize();
  } catch (e) {
    return s;
  }
}

export function normalizeHeadingDifference(text: string): string {
  // Check if the text starts with '##'
  if (text.startsWith("##")) {
    // Add a space after '##'
    text = "## " + text.substring(2);
  }

  return text;
}

export function attachedSubsectionElement(
  subsection: DocumentSubsection
): DocumentElement {
  const matchingElement = subsection.elements?.find(
    (element) => element.valid_until === null
  );
  if (matchingElement) {
    return matchingElement;
  } else return subsection.elements![0];
}

export function isDeletedSection(subsection: DocumentSubsection) {
  try {
    if (
      subsection.temporary_deleted != null ||
      subsection.pending_deleted_at != null
    ) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}

export function isDeletedSubsection(subsection: DocumentSubsection) {
  try {
    if (subsection.pending_deleted_at != null) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}

export const UTCtoLocalTime = (dateString: any) => {
  if (dateString == null || dateString == undefined) {
    return "N/A";
  } else {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Use 12-hour format
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Set the time zone
    };
    return date.toLocaleString(undefined, options); // Pass undefined for locale to use user's default locale
  }
};

export function getTableElements(documentObj: any) {
  const result: any[] = [];

  let tableCount = 0;
  let imageCount = 0;

  // Safely access sections
  const sections = documentObj.sections || [];

  sections.forEach((section: any) => {
    const subsections = section.subsections || [];
    subsections.forEach((subsection: any) => {
      const elements = subsection.elements || [];

      elements.forEach((el: any) => {
        if (el.content_type === "TABLE" || el.content_type === "IMAGE") {
          let parsedFormat: any = {};
          try {
            parsedFormat = JSON.parse(el.content_format) || {};
          } catch {
            console.log("INSIDE CATCH");
          }

          const displayCaption =
            el.content_type === "TABLE"
              ? !!parsedFormat.displayCaption
              : !!parsedFormat.DisplyCaption;

          const isNumberFormat = !!parsedFormat.isNumberFormat;
          const rawCaption = parsedFormat.caption || "";

          let finalCaption: string;
          let idStr: string;
          let typeLabel: string;

          if (el.content_type === "TABLE") {
            tableCount++;
            idStr = "et" + subsection.id;
            typeLabel = "Table";
            if (displayCaption && isNumberFormat) {
              finalCaption = `##${typeLabel} ${countTableElementInDocumentTemplate(
                {
                  documentTemplate: documentObj,
                  element: el,
                },
                "TABLE"
              )}`;
            } else {
              finalCaption = "[REF-MISSING]";
            }
          } else {
            imageCount++;
            idStr = "et" + subsection.id;
            typeLabel = "Figure";
            if (displayCaption && isNumberFormat) {
              finalCaption = `##${typeLabel} ${countTableElementInDocumentTemplate(
                {
                  documentTemplate: documentObj,
                  element: el,
                },
                "IMAGE"
              )}`;
            } else {
              finalCaption = "[REF-MISSING]";
            }
          }

          result.push({
            id: subsection.id,
            idstr: idStr,
            c_type:el.content_type,
            type: el.content_type.toLowerCase(),
            number: finalCaption,
            numberhash: `${finalCaption}@${subsection.id}`,
            relevantId: subsection.id,
          });
        }
      });
    });
  });

  return result;
}

/**
 * Get the editor for the specific element type.
 *
 * @param {DocumentElement} element
 * @param {DocumentSubsection} parentSubsection
 * @return {*}
 */
export function getEditorForDocumentElement(
  element: DocumentElement | null,
  parentSubsection: DocumentSubsection | null,
  parentSection: DocumentSection | DocumentSectionSummary,
  parentSection__: DocumentSection | null,
  documentTemplate: DocumentTemplate | DocumentTemplateSummary | any,
  workspaceId: number,
  organizationId: number,
  viewPreferences: ViewPreferences,
  forApproval: boolean,
  key: string,
  approvalsEnabled: boolean,
  approvalOf: number,
  readOnly?: boolean,
  isLibraryContentForm?: boolean
) {
  const tableData = getTableElements(documentTemplate);
  const tableDataArray = Array.isArray(tableData)
    ? tableData
    : tableData
    ? [tableData]
    : [];

  if (parentSection.heading === "Table of Contents Section") {
    if (element!.content_type === ContentType.TableOfContents) {
      return (
        <TOCElementEditor
          // key={parentSubsection.id + "_" + element.id}
          organizationId={organizationId}
          workspaceId={workspaceId}
          element={element}
          parentSubsection={parentSubsection}
          parentSection={parentSection}
          parentSection__={parentSection__}
          documentTemplate={documentTemplate}
          viewPreferences={viewPreferences}
          approvalsEnabled={approvalsEnabled}
          readOnly={isUserFromShareLink() || readOnly ? true : false}
        />
      );
    } else return <></>;
  } else if (
    parentSection.heading === "List of Figures" ||
    parentSection.heading === "List of Tables"
  ) {
    if (
      element!.content_type === ContentType.ListOfFigures ||
      element!.content_type === ContentType.listOfTables
    ) {
      return (
        <ListOfFiguresElementEditor
          // key={parentSubsection.id + "_" + element.id}
          organizationId={organizationId}
          workspaceId={workspaceId}
          element={element}
          parentSubsection={parentSubsection}
          parentSection={parentSection}
          parentSection__={parentSection__}
          documentTemplate={documentTemplate}
          viewPreferences={viewPreferences}
          approvalsEnabled={approvalsEnabled}
          readOnly={isUserFromShareLink() || readOnly ? true : false}
        />
      );
    } else return <></>;
  } else {
    switch (element!.content_type) {
      case ContentType.Image:
        return (
          <ImageElementEditor
            // key={parentSubsection.id + "_" + element.id}
            organizationId={organizationId}
            workspaceId={workspaceId}
            element={element}
            parentSubsection={parentSubsection}
            parentSection={parentSection}
            parentSection__={parentSection__}
            documentTemplate={documentTemplate}
            viewPreferences={viewPreferences}
            approvalsEnabled={approvalsEnabled}
            readOnly={isUserFromShareLink() || readOnly ? true : false}
          />
        );
      case ContentType.PageBreak:
        return (
          <PagebreakElementEditor
            // key={parentSubsection.id + "_" + element.id}
            organizationId={organizationId}
            workspaceId={workspaceId}
            element={element}
            parentSubsection={parentSubsection}
            parentSection={parentSection}
            parentSection__={parentSection__}
            documentTemplate={documentTemplate}
            viewPreferences={viewPreferences}
            approvalsEnabled={approvalsEnabled}
            readOnly={isUserFromShareLink() || readOnly ? true : false}
          />
        );

      case ContentType.TableOfContents:
        return (
          <TOCElementEditor
            // key={parentSubsection.id + "_" + element.id}
            organizationId={organizationId}
            workspaceId={workspaceId}
            element={element}
            parentSubsection={parentSubsection}
            parentSection={parentSection}
            parentSection__={parentSection__}
            documentTemplate={documentTemplate}
            viewPreferences={viewPreferences}
            approvalsEnabled={approvalsEnabled}
            readOnly={isUserFromShareLink() || readOnly ? true : false}
          />
        );

      case ContentType.ListOfFigures:
        return (
          <ListOfFiguresElementEditor
            // key={parentSubsection.id + "_" + element.id}
            organizationId={organizationId}
            workspaceId={workspaceId}
            element={element}
            parentSubsection={parentSubsection}
            parentSection={parentSection}
            parentSection__={parentSection__}
            documentTemplate={documentTemplate}
            viewPreferences={viewPreferences}
            approvalsEnabled={approvalsEnabled}
            readOnly={isUserFromShareLink() || readOnly ? true : false}
          />
        );

      case ContentType.listOfTables:
        return (
          <ListOfFiguresElementEditor
            // key={parentSubsection.id + "_" + element.id}
            organizationId={organizationId}
            workspaceId={workspaceId}
            element={element}
            parentSubsection={parentSubsection}
            parentSection={parentSection}
            parentSection__={parentSection__}
            documentTemplate={documentTemplate}
            viewPreferences={viewPreferences}
            approvalsEnabled={approvalsEnabled}
            readOnly={isUserFromShareLink() || readOnly ? true : false}
          />
        );
      case ContentType.columnStart:
        return (
          <ColumnElementEditor
            // key={parentSubsection.id + "_" + element.id}
            organizationId={organizationId}
            workspaceId={workspaceId}
            element={element}
            parentSubsection={parentSubsection}
            parentSection={parentSection}
            parentSection__={parentSection__}
            documentTemplate={documentTemplate}
            viewPreferences={viewPreferences}
            approvalsEnabled={approvalsEnabled}
            readOnly={isUserFromShareLink() || readOnly ? true : false}
          />
        );

      case ContentType.columnEnd:
        return (
          <ColumnElementEditor
            // key={parentSubsection.id + "_" + element.id}
            organizationId={organizationId}
            workspaceId={workspaceId}
            element={element}
            parentSubsection={parentSubsection}
            parentSection={parentSection}
            parentSection__={parentSection__}
            documentTemplate={documentTemplate}
            viewPreferences={viewPreferences}
            approvalsEnabled={approvalsEnabled}
            readOnly={isUserFromShareLink() || readOnly ? true : false}
          />
        );

      case ContentType.Spacer:
        return (
          <SpacerElementEditor
            // key={parentSubsection.id + "_" + element.id}
            organizationId={organizationId}
            workspaceId={workspaceId}
            element={element}
            parentSubsection={parentSubsection}
            parentSection={parentSection}
            parentSection__={parentSection__}
            documentTemplate={documentTemplate}
            viewPreferences={viewPreferences}
            approvalsEnabled={approvalsEnabled}
            readOnly={isUserFromShareLink() || readOnly ? true : false}
          />
        );

      case ContentType.Table:
        return (
          <TableElementEditor
            // key={parentSubsection.id + "_" + key + "_" + element.id}
            organizationId={organizationId}
            workspaceId={workspaceId}
            element={element}
            parentSubsection={parentSubsection}
            parentSection={parentSection}
            parentSection__={parentSection__}
            documentTemplate={documentTemplate}
            viewPreferences={viewPreferences}
            forApproval={forApproval}
            approvalsEnabled={approvalsEnabled}
            readOnly={isUserFromShareLink() || readOnly ? true : false}
            tableDataArray={tableDataArray}
            isLibraryContentForm={isLibraryContentForm}
          />
        );
      case ContentType.dynamicTable:
        return (
          <DynamicTableElementEditor
            // key={parentSubsection.id + "_" + key + "_" + element.id}
            organizationId={organizationId}
            workspaceId={workspaceId}
            element={element}
            parentSubsection={parentSubsection}
            parentSection={parentSection}
            parentSection__={parentSection__}
            documentTemplate={documentTemplate}
            viewPreferences={viewPreferences}
            forApproval={forApproval}
            approvalsEnabled={approvalsEnabled}
            readOnly={isUserFromShareLink() || readOnly ? true : false}
          />
        );

      case ContentType.Text:
        return (
          <DocumentElementEditor
            key={
              element != null && element.id && parentSubsection
                ? parentSubsection.id + "_" + "_" + element.id
                : parentSection + "_DocumentElementEditor"
            }
            organizationId={organizationId}
            workspaceId={workspaceId}
            element={element}
            parentSubsection={parentSubsection}
            parentSection={parentSection}
            documentTemplate={documentTemplate}
            isHeading={false}
            viewPreferences={viewPreferences}
            forApproval={forApproval}
            approvalsEnabled={approvalsEnabled}
            approvalOf={approvalOf}
            readOnly={isUserFromShareLink() || readOnly ? true : false}
            isLibraryContentForm={isLibraryContentForm}
          />
        );

      case ContentType.Heading:
        return (
          <DocumentElementEditor
            key={
              element != null && element.id && parentSubsection
                ? parentSubsection.id + "_" + "_" + element.id
                : parentSection + "_DocumentElementEditor"
            }
            organizationId={organizationId}
            workspaceId={workspaceId}
            element={element}
            parentSubsection={parentSubsection}
            parentSection={parentSection}
            documentTemplate={documentTemplate}
            isHeading={false}
            viewPreferences={viewPreferences}
            forApproval={forApproval}
            approvalsEnabled={approvalsEnabled}
            approvalOf={approvalOf}
            readOnly={isUserFromShareLink() || readOnly ? true : false}
            isLibraryContentForm={isLibraryContentForm}
          />
        );

      default:
        // For everything else, use the markdown editor
        return (
          <DocumentElementEditor
            key={
              element != null && element.id && parentSubsection
                ? parentSubsection.id + "_" + "_" + element.id
                : parentSection + "_DocumentElementEditor"
            }
            organizationId={organizationId}
            workspaceId={workspaceId}
            element={element}
            parentSubsection={parentSubsection}
            parentSection={parentSection}
            documentTemplate={documentTemplate}
            isHeading={false}
            viewPreferences={viewPreferences}
            forApproval={forApproval}
            approvalsEnabled={approvalsEnabled}
            approvalOf={approvalOf}
            readOnly={isUserFromShareLink() || readOnly ? true : false}
            isLibraryContentForm={isLibraryContentForm}
          />
        );
    }
  }
}
