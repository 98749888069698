import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import useActions from "../../../../app/hooks";
import {
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateSummary,
} from "../../../../types/DocumentTemplate";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { useMemo } from "react";
import { TroubleshootTwoTone } from "@mui/icons-material";
import validateReferences from "./validateReferences";
import { useSelector } from "react-redux";
import { RootState } from "src/app/rootReducer";
import { apiGetCrossReferedElementIds } from "src/services/crossReferencingAPI";

export default function DeleteUnsharedConfirm(props: {
  isHeading: boolean;
  parentSection: DocumentSection;
  parentSubsection: DocumentSubsection | any;
  customMsg?: string;
  closeDialogHandler: any;
  sanitizedNumberings?: any;
  organizationId?: number;
  workspaceId?: number;
  documentTemplate?: DocumentTemplate;
  getRefferedData?: any;
}) {
  const [isHeading, setIsHeading] = React.useState<boolean>();
  const [parentSection, setParentSection] =
    React.useState<DocumentSection | null>();
  const [parentSubsection, setParentSubsection] =
    React.useState<DocumentSubsection | null>();
  const [clearFromReferences, setclearFromReferences] = useState(false);
  const [refData, setRefData] = useState<any>([]);
  const [filteredData, setfilteredData] = useState<any[]>([]);
  const [isApiLoading, setisApiLoading] = useState(false);

  const apigetCrossReferedReferences = async () => {
    setisApiLoading(true);
    try {
      const payload = {
        document_section_id: props.parentSection.id,
        element_id: props.parentSubsection?.id,
      };
      const response = await apiGetCrossReferedElementIds(
        props.organizationId,
        props.workspaceId,
        props.documentTemplate?.id,
        payload
      );
      const result_response = JSON.parse(response.data[0].result_array);
      setRefData(result_response);
      setisApiLoading(false);
    } catch (error) {
      console.error("Error in apigetCrossReferedReferences:", error);
      return [];
    }
  };

  useEffect(() => {
    if (!props.sanitizedNumberings && !props.organizationId) {
      console.log("do nothing")
    } else {
      apigetCrossReferedReferences();
    }
  }, []);

  useEffect(() => {
    if (refData?.length > 0) {
      refData.forEach((item: any) => {
        props.sanitizedNumberings?.forEach((itemm: any) => {
          const id = item.match(/\d+/g);
          if (
            parseInt(id, 10) === itemm?.subSectionId ||
            parseInt(id, 10) === itemm?.parentSectionID
          ) {
            setfilteredData((prevFilteredData) => {
              return prevFilteredData.some((data) => data.id === itemm.id)
                ? prevFilteredData
                : [...prevFilteredData, itemm];
            });
          }
        });
      });
    }
  }, [refData]);

  const msg = props.customMsg
    ? props.customMsg
    : `Are you sure you want to delete this ${
        isHeading ? "section" : "subsection"
      }?`;

  useEffect(() => {
    if (props.parentSection != null && props.parentSubsection != null) {
      setIsHeading(false);
      setParentSection(props.parentSection);
      setParentSubsection(props.parentSubsection);
    } else if (props.parentSection != null && props.parentSubsection == null) {
      setIsHeading(true);
      setParentSection(props.parentSection);
      setParentSubsection(props.parentSubsection);
    }
  }, [props.isHeading, props.parentSection]);

  const actions = useActions();
  const handleClose = () => {
    props.closeDialogHandler();
  };

  const handleDelete = () => {
    if (isHeading) {
      actions.deleteSection(parentSection as DocumentSection);
    } else {
      actions.deleteSubsection({
        section: parentSection as DocumentSection,
        subSection: parentSubsection as DocumentSubsection,
      });
    }
    props.closeDialogHandler();
  };

  const data = {
    section: parentSection as DocumentSection,
    subsections: parentSubsection as DocumentSubsection,
  };

  useEffect(() => {
    if (props.sanitizedNumberings?.length > 0) {
      if (!data.section && !data.subsections) {
        // console.log("DATA IS YET NOT AVAILABLE");
      } else {
        const validateData = validateReferences(
          data,
          props.sanitizedNumberings
        );
        if (validateData) {
          setclearFromReferences(false);
        } else {
          setclearFromReferences(true);
        }
      }
    }
  }, [parentSection, parentSubsection]);

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle sx={{ fontWeight: "bold" }}>
        Confirm Delete {isHeading ? "Section" : "Subsection"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" color="textPrimary">
            {msg}
          </Typography>
          {!clearFromReferences && props.sanitizedNumberings?.length > 0 && (
            <Alert sx={{ my: 2 }} severity="warning" variant="outlined">
              {`This ${
                isHeading ? "Section" : "subsection"
              } contains reference(s)`}
            </Alert>
          )}
          {filteredData?.length > 0 && (
            <Alert sx={{ my: 2 }} severity="warning" variant="outlined">
              {`Please remove the reference(s) from the following Sections/Subsections to proceed with their deletion.`}
              {Array.from(
                new Set(filteredData?.map((item: any) => item.number))
              ).map((uniqueNumber, index) => (
                <List key={index}>{uniqueNumber.replace("##", "")}</List>
              ))}
            </Alert>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          variant="contained"
          color="primary"
          autoFocus
          disabled={isApiLoading || filteredData.length > 0}
        >
          {isApiLoading ? <CircularProgress size={23} /> : "delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
